<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="40%" :show-close="false">
      <div class="box">
        <div class="baba">
          <div class="item fix" >
            <div class="imge">
              <img src="../../assets/images/Popup/Image 1.png" alt />
            </div>
            <div class="title">思德科技公众号</div>
          </div>

          <div class="item fix"  style=" ">
            <div class="imge">
              <img src="../../assets/images/Popup/Image 2.png" alt />
            </div>
            <div class="title">5G+智慧展馆</div>
          </div>
          <div class="item"  >
            <div class="imge">
              <img src="../../assets/images/Popup/Image 3.png" alt />
            </div>
            <div class="title">DICT营销宝典</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";
export default {
  mounted() {
    this.$bus.$on("valueUpdate", value => {
        console.log(value)
      this.dialogVisible = value;
    });
  },
  computed: {
    ...mapState(["isShow"])
  },
  data() {
    return {
      dialogVisible: this.isShow
    };
  }
};
</script>

<style scoped>
.box {
  height: 400px;
  display: flex;
  align-items: center;
 width: 100%;
}
.baba   {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;

}
.baba .item{
        width: 150px;
        text-align: center;
        font-size: 16px;
        font-family: 'SC_Light';
       

}
.fix{
  margin-right: 68.1px;
}
.baba .item img{
    width: 100%;
}
</style>