<template>
  <div class="personnel">
    <img src="../../assets/images/jobIMG.png" class="jobBanner" />
    <div class="personelJob">
      <div class="box_left">
        <div class="fbtime">
          <div class="shijian">发布时间：{{data.createTime}}</div>
          <!-- <div class="share">
            分享
            <img src="../../assets/images/share.png" />
          </div> -->
        </div>
        <div class="position">{{data.name}}</div>
        <div
          class="profile"
        >{{ data.basic }}</div>
        <div class="line"></div>
        <div class="position_work">工作职责</div>
        <div class="workList">
          <div v-html="data.desc" ></div>
         
          <div></div>
        </div>
        <div class="position_work">任职要求</div>
        <div class="workList">
          <div v-html="data.duty"></div>
        </div>

        <div class="position_work">培养方向</div>
        <div class="workList">
            <div v-html="data.request" ></div>
        </div>
        <div class="position_work">培养时间</div>
        <div class="workList">
            <div v-html="data.fangxiang" ></div>
        </div>
        <div class="applyJob" @click="applyJob">申请职位</div>
      </div>
      <div class="box_right">
        <div class="newJob">最新职位</div>
        <div @click="ToDetails(item.id)" class="jobList" v-for="(item,k) of jobList" :key="k"><span>急</span>{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      jobList:[]

    };
  },
  mounted() {
   

    this.$axios.get("getRecruitBySchool").then(res => {
      this.data = res.data.data;

      let aaa = this.data.createTime.replace("T", " ");
      this.data.createTime = aaa;
    
      let duty = this.data.duty.replace(/\n/g, "</br>");
      this.data.duty = duty;

      let desc = this.data.desc.replace(/\n/g, "</br>");
      this.data.desc = desc;

      
     


      let fangxiang = this.data.fangxiang.replace(/\n/g, "</br>");
      this.data.fangxiang = fangxiang;

      let request = this.data.request.replace(/\n/g, "</br>");
      this.data.request = request;


      
      console.log(this.data);
    });


    this.$axios('getNewRecruit?count=5').then(res=>{
    this.jobList=res.data.data
    console.log('职位列表：',this.data );
})
  },

  methods: {
    applyJob(){
        window.open(this.data.bossLink,'_blank');

    },
    ToDetails(id) {
      console.log("我送过去了:", id);
      this.$router.push({
        name: "categoryDetails",
        params: {
          id
        }
      });
    },
  }
};
</script>
<style scoped>
.top {
  width: 100%;
  background-color: #6461a6;
  height: 64px;
  position: fixed;
  z-index: 999;
}

.logo {
  width: 75px;
  height: 37px;
  background-image: url(../../assets/logo.png);
  background-size: 100% 100%;
  margin-left: 70px;
  margin-top: 13.5px;
  float: left;
}

.ability {
  font-size: 14px;
  float: right;
  margin-right: 9px;
  line-height: 64px;
  display: flex;
}

.items {
  color: #fff;
  margin-right: 61px;
  margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-family: "SC_Light";
}

.menu:hover {
  z-index: 999;
  cursor: pointer;
  overflow: visible;
  font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
  border-top: 2px solid #fff;
}

.drop {
  background: #6461a6;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  font-family: "SC_Light";
}

.drop1 {
  background: transparent;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
}

.drop:hover {
  font-family: "SC_Bold";
  cursor: pointer;
}
</style>

<style scoped>
/* 22 16 14 */
.personnel {
  width: 100%;
  padding-bottom: 80px;
  overflow: hidden;
}

.jobBanner {
  width: 100%;
  height: 721px;
}

.personelJob {
  width: 1280px;
  overflow: hidden;
  margin: 100px auto 0;
  padding-bottom: 100px;
}

.box_left {
  float: left;
  width: 870px;
  padding: 0 30px 0 50px;
  background: #f2f2f2;
}

.fbtime {
  overflow: hidden;
  margin-top: 34px;
  margin-bottom: 34px;
  font-size: 16px;
  color: #333333;
  font-family: "SC_Regular";
}

.fbtime .shijian {
  float: left;
}

.fbtime .share {
  float: right;
}

.share img {
  width: 16px;
  height: 16px;
  vertical-align: bottom;
}

.box_left div {
  flex: 1;
}

.position {
  font-size: 22px;
  color: #333333;
  font-family: "SC_Bold";
  line-height: 40px;
  text-align: left;
}

.profile {
  color: rgba(51, 51, 51, 0.5);
  font-size: 14px;
  line-height: 30px;
  text-align: left;
}

.line {
  width: 100%;
  height: 1px;
  background: rgba(51, 51, 51, 0.5);
  margin-top: 20px;
}

.position_work {
  width: 100%;
  font-size: 20px;
  line-height: 35px;
  margin-top: 40px;
  color: #64619e;
  font-family: "SC_Regular";
  text-align: left;
}

.applyJob {
  width: 116px;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  text-align: center;
  border-radius: 6px;
  color: #fff;
  background: #6461a6;
  margin: 100px auto 50px;
}

.workList {
  width: 100%;
  line-height: 30px;
  font-size: 14px;
  color: #333333;
}

.workList div {
  width: 100%;
  text-align: left;
}

.box_right {
  float: right;
  width: 300px;
  background: #ccc;
  margin-left: 30px;
  padding-bottom: 50px;
  background: #f2f2f2;
}

.box_right div {
  margin-left: 30px;
  text-align: left;
}

.newJob {
  font-size: 30px;
  font-family: "SC_Bold";
  color: #333333;
  margin-top: 34px;
  margin-bottom: 20px;
}

.jobList {
  color: #333333;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 15px;
}

.jobList span {
  width: 21px;
  height: 21px;
  line-height: 21px;
  font-size: 14px;
  color: #ffffff;
  background: #f00;
  display: inline-block;
  margin-right: 16px;
  text-align: center;
}
</style>