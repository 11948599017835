<template>
  <div class="body">
    <div class="center">
      <div class="industryScenarioBG">
        <!-- <img :src="data.bgimgUrl" alt v-if="isVideo==false" /> -->
        <video
          ref="video"
          class="video-js vjs-default-skin image.png vjs-big-play"
          autoplay
          loop
          muted
          width="100%"
          height="100%"
          :src="data.bgimgUrl"
        >
          <!-- <source :src="data.bgimgUrl" /> -->
          <!-- <source src="http://36.134.151.244:4529/723c8609-bf08-466d-aa33-8b46ac0a8a8e.mp4" /> -->
        </video>
      </div>
      <div class="industryScenariotitle">{{data.title}}</div>
      <div class="industryScenariobox">
        <div class="industryScenarioboxOne" @click="handleVideo">
          <div class="industryScenarioboxOnetitle">视频体验</div>
          <div class="industryScenarioboxOnePic"></div>
          <div class="industryScenarioboxOnefont">点击观看</div>
        </div>
        <div class="industryScenarioboxTwo">
          <div class="industryScenarioboxtwotitle">手机体验</div>
          <div class="industryScenarioboxtwoPic"></div>
          <div class="industryScenarioboxOnefont">扫码体验</div>
        </div>
        <div class="industryScenarioboxThree" @click="handleVR">
          <div class="industryScenarioboxthreetitle">VR体验</div>
          <div class="industryScenarioboxthreePic"></div>
          <div class="industryScenarioboxOnefont">点击进入</div>
        </div>
      </div>

      <div class="industryScenariotitle">体验亮点</div>
      <div class="industrycenterobox">
        <div class="industrycenterleft">
          <img :src="data.HighlightsImg1" alt />
        </div>
        <div class="industrycenterright">
          <div class="industrycenterrighttitle">{{data.HighlightsTitle1}}</div>
          <div class="line"></div>
          <div class="industrycenterrightfont">{{data.HighlightsContent1_row1}}</div>
          <div class="industrycenterrightfont">{{data.HighlightsContent1_row2}}</div>
        </div>
      </div>

      <div class="industrycenterobox">
        <div class="industrycenterright">
          <div class="industrycenterrighttitle">{{data.HighlightsTitle2}}</div>
          <div class="line"></div>
          <div class="industrycenterrightfont">{{data.HighlightsContent2_row1}}</div>
          <div class="industrycenterrightfont">{{data.HighlightsContent2_row2}}</div>
        </div>
        <div class="industrycenterleft">
          <img :src="data.HighlightsImg2" alt />
        </div>
      </div>

      <div class="industrycenterobox">
        <div class="industrycenterleft">
          <img :src="data.HighlightsImg3" alt />
        </div>
        <div class="industrycenterright">
          <div class="industrycenterrighttitle">{{data.HighlightsTitle3}}</div>
          <div class="line"></div>
          <div class="industrycenterrightfont">{{data.HighlightsContent3_row1}}</div>
          <div class="industrycenterrightfont">{{data.HighlightsContent3_row2}}</div>
        </div>
      </div>

      <div class="industryScenariotitle">其他行业场景</div>
      <div class="industryScenariobox1">
        <div
          v-for="(item,index) of industryDataList"
          :key="index"
          :class="`SenseOfWorth${index+1}`"
          :id="`SenseOfWorth${index+1}`"
          @mouseenter="onEnter(index+1)"
          @mouseleave="onLeave(index+1)"
          @click="jumpToBrother(item.id)"
        >
          <div class="hoverBG" :id="`hoverBG${index+1}`" >
            <p> {{  item.title}}</p>
          </div>
        </div>
        <!-- <div
          class="SenseOfWorth2"
          ref="SenseOfWorth2"
          @mouseenter="onEnterT2"
          @mouseleave="onLeaveTd2"
        >
          <div class="hoverBG" ref="hoverBG2">
            <p>智慧工地</p>
          </div>
        </div>
        <div
          class="SenseOfWorth3"
          ref="SenseOfWorth3"
          @mouseenter="onEnterT3"
          @mouseleave="onLeaveTd3"
        >
          <div class="hoverBG" ref="hoverBG3">
            <p>智慧校园</p>
          </div>
        </div>
        <div
          class="SenseOfWorth4"
          ref="SenseOfWorth4"
          @mouseenter="onEnterT4"
          @mouseleave="onLeaveTd4"
        >
          <div class="hoverBG" ref="hoverBG4">
            <p>智慧医院</p>
          </div>
        </div>
        <div
          class="SenseOfWorth5"
          ref="SenseOfWorth5"
          @mouseenter="onEnterT5"
          @mouseleave="onLeaveTd5"
        >
          <div class="hoverBG" ref="hoverBG5">
            <p>智慧工厂</p>
          </div>
        </div>
        <div
          class="SenseOfWorth6"
          ref="SenseOfWorth6"
          @mouseenter="onEnterT6"
          @mouseleave="onLeaveTd6"
        >
          <div class="hoverBG" ref="hoverBG6">
            <p>智慧景区</p>
          </div>
        </div>-->
      </div>
    </div>

    <!-- 视频弹窗 -->
    <div class="videoPoup" v-if="showVideoPoup">
      <div class="mask">
        <div class="cancel" @click="hide">
          <img src="../assets/videoCancel.png" alt />
        </div>
        <video ref="video" class="video-js vjs-default-skin" controls autoplay loop>
          <source :src="videoShipin" />
        </video>
      </div>
    </div>

    <!-- 暂未开放 -->
    <div class="videoPoup1" v-if="showVideoPoup1">该功能暂未开放!</div>
    <!-- 暂无视频 -->
    <div class="videoPoup2" v-if="showVideoPoup2">暂无视频!</div>
  </div>
</template>

<script>
import Bottom from "@/components/bottom.vue";
import { createLogger } from "vuex";
export default {
  components: {
    Bottom
  },
  data() {
    return {
      id: 0,
      data: {},
      isVideo: false,

      industryDataList: [],

      videoList: [
        {
          //智慧港口
          id: 145,
          videoUrl: "https://bk.sidex.cn/static/SmartPort.m4v"
        },
        {
          //智慧工地
          id: 29,
          videoUrl: "https://bk.sidex.cn/static/smartSite.m4v"
        }
      ],
      showVideoPoup: false,
      showVideoPoup1: false,
      showVideoPoup2: false,
      videoShipin: ""
    };
  },
  watch: {
    $route() {
      if (this.$route.params.id !== undefined) {
        let id = this.$route.params.id;
        this.id = id;
        this.$axios(`getIndustryDetail?id=${id}`).then(res => {
          this.data = JSON.parse(res.data.data);
          // this.data = res.data.data;
          // console.log("IndustryScenario.vue______________", this.data.bgimgUrl);
          var index = this.data.bgimgUrl.lastIndexOf(".");
          var str = this.data.bgimgUrl.substring(
            index + 1,
            this.data.bgimgUrl.length
          );
          console.log(str);
          if (str == "mp4" || str == "m4v") {
            this.isVideo = true;
          } else {
            this.isVideo = false;
          }
        });
      }
    }
  },
  mounted() {
    this.getIndustryData();
    let id = this.$route.params.id;

    this.id = id;
    this.$axios(`getIndustryDetail?id=${id}`).then(res => {
      this.data = JSON.parse(res.data.data);
      // this.data = res.data.data;
      console.log("水水水水水水水水水水水水水水水水水", this.data);
      // var index = this.data.bgimgUrl.lastIndexOf(".");
      // var str = this.data.bgimgUrl.substring(
      //   index + 1,
      //   this.data.bgimgUrl.length
      // );
      // console.log(str);
      // if (str == "mp4") {
      //   this.isVideo = true;

      // } else {
      //   this.isVideo = false;
      // }
    });

    // if (this.isVideo) {
    //   let video = document.getElementsByTagName("video");
    //   video.play();
    // }
  },
  methods: {
    getIndustryData() {
      this.$axios("getIndustry").then(res => {
        console.log("123231232", res);
        this.industryDataList = res.data.data;
      });
    },

    hide() {
      this.showVideoPoup = false;
    },

    handleVideo() {
      //视频
      let ids = this.$route.params.id;
      // console.log('视频___',this.videoList);
      // console.log('视频___',ids);
      this.showVideoPoup = true;
      console.log(this.showVideoPoup);
      if (ids == 29) {
        this.videoShipin = "https://bk.sidex.cn/static/smartSite.m4v";
      } else if (ids == 145) {
        this.videoShipin = "https://bk.sidex.cn/static/SmartPort.m4v";
      } else if (ids == 147) {
        this.videoShipin = "http://36.134.151.244:4529/智慧景区0313.mp4";
      } else if (ids == 148) {
        this.videoShipin = "http://36.134.151.244:4529/智慧酒店-完整版1121.mp4";
      } else if (ids == 161) {
        this.videoShipin =
          "http://36.134.151.244:4529/智慧医院包装_0112输出.mp4";
      } else if (ids == 163) {
        this.videoShipin = "http://36.134.151.244:4529/智慧校园.mp4";
      } else {
        this.showVideoPoup = false;
        this.$alert("抱歉暂未视频!", "标题名称", {
          confirmButtonText: "确定",
          title: ""
        });
      }
    },
    handlePhone() {
      //手机体验
      this.$alert("抱歉功能暂未开放!", "标题名称", {
        confirmButtonText: "确定",
        title: ""
      });
    },
    handleVR() {
      //  VR
      this.$alert("抱歉功能暂未开放!", "标题名称", {
        confirmButtonText: "确定",
        title: ""
      });
    },

    //点击跳转到其他行业场景


    jumpToBrother(id){

      this.$router.push(
      {
        name:'IndustryScenario',
        params:{id}
      }

      );
    },

    onEnter(index) {
      
      let SenseOfWorth1 = document.getElementById("SenseOfWorth1");
      let SenseOfWorth2 = document.getElementById("SenseOfWorth2");
      let SenseOfWorth3 = document.getElementById("SenseOfWorth3");
      let SenseOfWorth4 = document.getElementById("SenseOfWorth4");
      let SenseOfWorth5 = document.getElementById("SenseOfWorth5");
      let SenseOfWorth6 = document.getElementById("SenseOfWorth6");
      let hoverBG1 = document.getElementById("hoverBG1");
      let hoverBG2 = document.getElementById("hoverBG2");
      let hoverBG3 = document.getElementById("hoverBG3");
      let hoverBG4 = document.getElementById("hoverBG4");
      let hoverBG5 = document.getElementById("hoverBG5");
      let hoverBG6 = document.getElementById("hoverBG6");

      if (index == 1) {
        SenseOfWorth1.style.zIndex = "99";
        hoverBG1.style.display = "block";
      } else if (index == 2) {
        hoverBG1.style.display = "none";
        hoverBG2.style.display = "block";
        SenseOfWorth1.style.zIndex = "8";
        SenseOfWorth2.style.zIndex = "99";
      }else if(index==3){
        hoverBG3.style.display = "block"; // this.SenseOfWorth2 = false
    hoverBG1.style.display = "none";
      SenseOfWorth1.style.zIndex = "8";
      SenseOfWorth3.style.zIndex = "99";

      } else if(index==4){
      hoverBG1.style.display = "none";
      hoverBG4.style.display = "block";
      SenseOfWorth1.style.zIndex = "0";
      SenseOfWorth2.style.zIndex = "1";
      SenseOfWorth3.style.zIndex = "2";
      SenseOfWorth4.style.zIndex = "99";
      }else if(index==5){
      hoverBG1.style.display = "none";
      hoverBG5.style.display = "block";
      SenseOfWorth1.style.zIndex = "0";
      SenseOfWorth2.style.zIndex = "1";
      SenseOfWorth3.style.zIndex = "2";
      SenseOfWorth3.style.zIndex = "3";
      SenseOfWorth5.style.zIndex = "99";


      }else if(index==6){
      hoverBG1.style.display = "none";
      hoverBG6.style.display = "block";
      SenseOfWorth1.style.zIndex = "0";
      SenseOfWorth2.style.zIndex = "1";
      SenseOfWorth3.style.zIndex = "2";
      SenseOfWorth4.style.zIndex = "3";
      SenseOfWorth5.style.zIndex = "4";
      SenseOfWorth6.style.zIndex = "99";

      }
    },
    onLeave(index) {
      let SenseOfWorth1 = document.getElementById("SenseOfWorth1");
      let SenseOfWorth2 = document.getElementById("SenseOfWorth2");
      let SenseOfWorth3 = document.getElementById("SenseOfWorth3");
      let SenseOfWorth4 = document.getElementById("SenseOfWorth4");
      let SenseOfWorth5 = document.getElementById("SenseOfWorth5");
      let SenseOfWorth6 = document.getElementById("SenseOfWorth6");
      let hoverBG1 = document.getElementById("hoverBG1");
      let hoverBG2 = document.getElementById("hoverBG2");
      let hoverBG3 = document.getElementById("hoverBG3");
      let hoverBG4 = document.getElementById("hoverBG4");
      let hoverBG5 = document.getElementById("hoverBG5");
      let hoverBG6 = document.getElementById("hoverBG6");
      console.log(index);
      if (index == 1) {
        let SenseOfWorth1 = document.getElementById("SenseOfWorth1");

        SenseOfWorth1.style.zIndex = "10";
      } else if (index == 2) {
        hoverBG2.style.display = "none";
        hoverBG1.style.display = "block";
        SenseOfWorth1.style.zIndex = "10";
        SenseOfWorth2.style.zIndex = "9";
      }else if (index==3){
        hoverBG3.style.display = "none";
      hoverBG1.style.display = "block";
      SenseOfWorth1.style.zIndex = "10";
      SenseOfWorth3.style.zIndex = "8";

      }else if (index==4){
       hoverBG4.style.display = "none";
     hoverBG1.style.display = "block";
      SenseOfWorth1.style.zIndex = "10";
      SenseOfWorth2.style.zIndex = "9";
     SenseOfWorth3.style.zIndex = "8";
      SenseOfWorth4.style.zIndex = "7";
      } else if (index==5){
      hoverBG5.style.display = "none";
      hoverBG1.style.display = "block";
      SenseOfWorth1.style.zIndex = "10";
      SenseOfWorth2.style.zIndex = "9";
      SenseOfWorth3.style.zIndex = "8";
      SenseOfWorth4.style.zIndex = "7";
      SenseOfWorth5.style.zIndex = "6";

      }else if(index==6){
      hoverBG1.style.display = "block";
      hoverBG6.style.display = "none";
      SenseOfWorth1.style.zIndex = "10";
      SenseOfWorth2.style.zIndex = "9";
      SenseOfWorth3.style.zIndex = "8";
      SenseOfWorth4.style.zIndex = "7";
      SenseOfWorth5.style.zIndex = "6";
      SenseOfWorth6.style.zIndex = "5";
      }


    },


  }
};
</script>


<style scoped>
.center {
  width: 100%;
}
.industryScenarioBG {
  height: 980px;
  margin-bottom: 100px;
  width: 100%;
}
.industryScenarioBG img {
  width: 100%;
  height: 100%;
}

.industryScenariotitle {
  margin-top: 100px;
  text-align: center;
  font-size: 30px;
  font-family: "SC_Bold";
  margin-bottom: 60px;
}

.industryScenariobox {
  width: 1200px;
  height: 346px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 100px;
}

.industryScenarioboxOne {
  width: 370px;
  height: 300px;
  margin-right: 43px;
  border: 1px solid rgb(51, 51, 51, 0.4);
  padding-top: 46px;
  transition: all 1s;
}
.industryScenarioboxOne:hover {
  transform: scale(1.1);
}
.industryScenarioboxTwo {
  width: 371px;
  height: 300px;
  margin-right: 43px;
  border: 1px solid rgb(51, 51, 51, 0.4);
  padding-top: 46px;
  transition: all 1s;
}
.industryScenarioboxTwo:hover {
  transform: scale(1.1);
}
.industryScenarioboxThree {
  width: 371px;
  height: 300px;
  border: 1px solid rgb(51, 51, 51, 0.4);
  padding-top: 46px;
  transition: all 1s;
}
.industryScenarioboxThree:hover {
  transform: scale(1.1);
}
.industryScenarioboxOnetitle {
  text-align: center;
  font-size: 24px;
  color: #333333;
  font-family: "SC_Bold";
  margin-bottom: 50px;
}

.industryScenarioboxOnePic {
  width: 93px;
  height: 93px;
  background-image: url("../assets/industryScenarioboxOnePic.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 57px;
}

.industryScenarioboxOnefont {
  text-align: center;
  font-size: 18px;
  color: #333333;
}

.industryScenarioboxtwotitle {
  text-align: center;
  font-size: 24px;
  color: #333333;
  font-family: "SC_Bold";
  margin-bottom: 33px;
}

.industryScenarioboxtwoPic {
  width: 123px;
  height: 123px;
  background-image: url("../assets/industryScenarioboxtwoPic.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 43px;
}

.industryScenarioboxthreetitle {
  text-align: center;
  font-size: 24px;
  color: #333333;
  font-family: "SC_Bold";
  margin-bottom: 49px;
}

.industryScenarioboxthreePic {
  width: 93px;
  height: 93px;
  background-image: url("../assets/industryScenarioboxthreePic.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 57px;
}

.industrycenterobox {
  width: 1200px;
  height: 404px;
  background-color: #f7f7f7;
  margin: 0 auto;
  display: flex;
  /* margin-bottom: 40px; */
  margin-bottom: 40px;
}

.industrycenterleft {
  width: 773px;
  height: 404px;
  overflow: hidden;
}

.industrycenterright {
  width: 427px;
  height: 404px;
}

img {
  width: 773px;
  height: 404px;
  transition: all 0.4s;
}

.industrycenterleft img:hover {
  transform: scale(1.2);
}

.industrycenterrighttitle {
  font-size: 21px;
  font-family: "SC_Black";
  text-align: center;
  margin-bottom: 22px;
  margin-top: 139px;
  color: #333333;
}

.line {
  width: 40px;
  height: 2px;
  background-color: #000000;
  margin: 0 auto;
  margin-bottom: 31px;
}

.industrycenterrightfont {
  text-align: center;
  font-size: 14px;
  color: #333333;
  line-height: 30px;
}

.industryScenariobox1 {
  width: 1200px;
  height: 235.5px;
  margin: 0 auto;
  margin-bottom: 123px;
  /* background-color: red; */
  position: relative;
}
.border {
  position: absolute;
  height: 220px;
  width: 450px;
  border: 1px #fff solid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hoverBG {
  display: none;
  background-color: rgb(100, 97, 166, 60%);
  width: 470px;
  height: 235.5px;
  position: absolute;
  /* transition: all 2s; */
}
.hoverBG p {
  font-family: "SC_Bold";
  font-size: 20px;
  position: absolute;
  top: 40%;
  left: 50%;
  color: #f5f5f5;
  transform: translate(-50%, -50%);
}
.SenseOfWorth1 {
  width: 470px;
  height: 235.5px;
  display: inline-block;
  background-image: url(../assets/IndustryScenario1.png);
  background-size: 100% 100%;
  z-index: 10;
  position: absolute;
  left: 0;
}
.SenseOfWorth1 .hoverBG{
  display: block;
}

.SenseOfWorth2 {
  width: 470px;
  height: 235.5px;
  display: inline-block;
  background-image: url(../assets/IndustryScenario2.png);
  background-size: 100% 100%;
  z-index: 9;
  position: absolute;
  left: 150px;
}

.SenseOfWorth3 {
  width: 470px;
  height: 235.5px;
  display: inline-block;
  background-image: url(../assets/IndustryScenario6.png);
  background-size: 100% 100%;
  z-index: 8;
  position: absolute;
  left: 300px;
}

.SenseOfWorth4 {
  width: 470px;
  height: 235.5px;
  display: inline-block;
  background-image: url(../assets/IndustryScenari_jiudian.jpg);
  background-size: 100% 100%;
  z-index: 7;
  position: absolute;
  left: 450px;
}

.SenseOfWorth5 {
  width: 470px;
  height: 235.5px;
  display: inline-block;
  background-image: url(../assets/IndustryScenario4.png);
  background-size: 100% 100%;
  z-index: 6;
  position: absolute;
  left: 600px;
}

.SenseOfWorth6 {
  width: 470px;
  height: 235.5px;
  display: inline-block;
  background-image: url(../assets/IndustryScenario3.png);
  background-size: 100% 100%;
  z-index: 5;
  position: absolute;
  left: 750px;
}

.video-js {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.videoPoup1,
.videoPoup2 {
  text-align: center;
  width: 100%;
  height: 100px;
  line-height: 100px;
  color: #f00;
  position: fixed;
  top: 20%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
}
.videoPoup {
  width: 100vw;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(9, 9, 9, 0.62);
  z-index: 999;
  margin: 0 auto;
  top: 0;
}
.mask {
  width: 60vw;
  height: 50%;
  margin: 10% auto 0;
}
.mask .cancel {
  width: 20px;
  /* position: absolute; */
  z-index: 999;
  right: 20px;
  top: 8px;
}
.mask .cancel img {
  width: 20px;
  height: 20px;
}
</style>