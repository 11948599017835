<template>
  <div class="bottom">
    <!-- <div class="bottom_center">
            
    </div>-->
    <!-- <div class="bottom_top">
      <div class="bottom_top_img">
        <img src="../assets/logo3.png" />
      </div>
      <div class="attention">专注ICT体验营销&nbsp;&nbsp;全生命周期服务</div>
    </div>
    <div class="bottom_center">
      <div class="box_left">
        <div class="bottom_box_left">
          <div class="box_left_top">行业场景</div>
          <div
            class="box_left_bottom"
            v-for="(item,k) of IndustryScenarioChild"
            :key="k"
            @click="IndustryScenario(item.id)"
          >{{item.title }}</div>
        </div>

        <div class="bottom_box_left">
          <div class="box_left_top">解决方案</div>
          <div class="box_left_bottom" v-for="(item,k) of SoluitionChild " :key="k"  v-if="k<plus"  @click="jumpToSoluitionChild(item.id)">{{item.title}}</div>

          <div class="box_left_bottom" @click="showMore">查看更多></div>
        </div>

        <div class="bottom_box_left">
          <div class="box_left_top">精选案例</div>
          <div class="box_left_bottom">中国移动</div>
          <div class="box_left_bottom">中国电信</div>
          <div class="box_left_bottom">中国联通</div>
          <div class="box_left_bottom">十大智慧行业</div>
          <div class="box_left_bottom">其他</div>
        </div>

        <div class="bottom_box_left">
          <div class="box_left_top">服务能力</div>
          <div class="box_left_bottom">策略咨询</div>
          <div class="box_left_bottom">资源整合</div>
          <div class="box_left_bottom">项目交付</div>
          <div class="box_left_bottom">智能技术</div>
          <div class="box_left_bottom">业务包装</div>
          <div class="box_left_bottom">运营管理</div>
        </div>

        <div class="bottom_box_left">
          <div class="box_left_top">关于思德</div>
          <div class="box_left_bottom">发展愿景</div>
          <div class="box_left_bottom">企业价值观</div>
          <div class="box_left_bottom">企业资质</div>
          <div class="box_left_bottom">生态伙伴</div>
        </div>

        <div class="bottom_box_left paddingRight">
          <div class="box_left_top">加入我们</div>
          <div class="box_left_bottom">社会招聘</div>
          <div class="box_left_bottom">校园招聘</div>
          <div class="box_left_bottom">实习招聘</div>
          <div class="box_left_bottom">员工晋升</div>
        </div>
      </div>
      <div class="box_right">
        <div class="box_left_top box_right_top">联系我们</div>
        <div class="box_right_bottom">地址：雨花台区安德门大街25号维沃大厦A座10-12楼</div>
        <div class="box_right_bottom">电话：025-84650011</div>
        <div class="box_bottom_1">
          <div class="resourceCode">
            <img src="../assets/images/resourceCode01.png" />
            <span>思德科技公众号</span>
          </div>
          <div class="resourceCode">
            <img src="../assets/images/resourceCode02.png" />
            <span>5G+智慧展馆</span>
          </div>
          <div class="resourceCode">
            <img src="../assets/images/resourceCode03.png" />
            <span>DICT营销宝典</span>
          </div>
        </div>
      </div>
    </div>
    <div class="copyBottom">
      <div class="copyLeft">备案号：苏ICP备18010407号</div>
      <div class="copyright">
        <span>C</span>2023思德科技版权所有.
      </div>
    </div> -->

    <img class="bottomImg" src="../assets/bottom.png" alt="">


  </div>
</template>
<script>
export default {
  data() {
    return {
        plus:6,
      value: "",
      style: { backgroundColor: "" },
      scrollTop: 0,
      IndustryScenarioChild: [],
      SoluitionChild: [],
      jxal: [
        { name: "中国移动", eName: "yd" },
        { name: "中国电信", eName: "dx" },
        { name: "中国联通", eName: "lt" },
        { name: "十大智慧行业", eName: "zhhy" },
        { name: "其他", eName: "qt" }
      ],
      SolutionEname: ""
    };
  },
  mounted() {
    this.$axios.get("getIndustry").then(res => {
      console.log("我能听得到?topBanner的getIndustry", res);
      this.IndustryScenarioChild = res.data.data;
    });

    this.$axios.get("getSolutionByHomePage").then(res => {
      console.log("我能听得到?getSolutionByHomePage", res);
      this.SoluitionChild = res.data.data;
    });
  },
  methods: {
    showMore() {
            this.plus=100
    },
      //跳转到路由(Soluition)
      jumpToSoluitionChild(id) {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      hycj.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Bold'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      if (this.$route.path !== "/Solution") {
        this.$router.push({name:"Solution",params:{id}});
      }
   
    },
    //行业场景跳转
    IndustryScenario(id) {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      hycj.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Light'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      hycj.style.fontFamily='SC_Bold'


      this.$router.push(
      {
        name:'IndustryScenario',
        params:{id}
      }

      );

   
      
    },
  },
};
</script>
<style scoped>
.bottom {
  /* 285  570  870  1440  0.75   380  1160  */
  width: 100%;
  /* margin-top: 1.625rem; */
  /* padding-bottom: 106.66px; */
  background: #64619e;
}
.bottomImg{
  width: 100%;
}
.bottom_top {
  width: 1160px;
  overflow: hidden;
  margin: 0 auto 33.33px;
  padding-top: 22.5px;
  padding-bottom: 7px;
  border-bottom: 1px solid rgb(255, 255, 255, 50%);
  height: 50px;
}
.bottom_top .bottom_top_img {
  float: left;
  margin-left: 50px;
}
.bottom_top .bottom_top_img img {
  float: left;
  width:120.3px;
  height: 47px;
}
.bottom_top .attention {
  font-size: 24px;
  float: right;
  margin-right: 50px;
  line-height: 50px;
}

.bottom_center {
  width: 1032px;
  overflow: hidden;
  margin: 0 auto 22px;
  border-bottom: 1px solid rgb(255, 255, 255, 50%);
  padding-bottom: 25.9px;
  padding-left: 68px;
  padding-right: 68px;
}
.box_left {
  float: left;
  border-right: 1px solid rgb(255, 255, 255, 50%);
  /* padding-right: 25.85px; */
}
.box_left div {
  text-align: center;
  color: #ffffff;
}
.bottom_box_left {
  /* width: 80px; */
  text-align: center;
  color: #fff;
  float: left;
  margin-right: 25px;
}
.paddingRight {
  padding-right: 15px;
}
.box_left_top {
  font-family: "SC_Bold";
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 16px;
  cursor: default;
}
.box_left_bottom {
  font-family: "SC_Thin";
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 16px;
  cursor: pointer;
}
.box_left_bottom:hover {
  font-family: "SC_Light";
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 16px;
  cursor: pointer;
}
.box_right {
  float: left;
  margin-left: 15px;
  color: #ffffff;
  text-align: left;
}
.box_right_top {
  margin-bottom: 6px;
}
.box_right_bottom {
  font-family: "SC_Thin";
  line-height: 20px;
  font-size: 12px;
  margin-bottom: 6px;
}
.attention {
  font-size: 0.25rem;
  font-family: "SC_Bold";
  color: #ffffff;
}
.box_bottom_1 {
  margin-top: 35.55px;
  overflow: hidden;
}
.resourceCode {
  float: left;
  overflow: hidden;
  margin-right: 14.8px;
}
.resourceCode img {
  width: 71.5px;
  height: 72.3px;
  display: block;
  margin: 0 auto;
}
.resourceCode span {
  text-align: center;
  display: block;
  line-height: 20px;
  font-size: 12px;
  font-family: "SC_Thin";
  color: #ffffff;
  margin-top: 7.4px;
}
.copyBottom {
  width: 1032px;
  height: 20px;
  margin: 0 auto;
}
.copyLeft,
.copyright {
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  font-family: "SC_Light";
}
.copyLeft {
  float: left;
}
.copyright {
  float: right;
}
.copyright span {
  width: 12px;
  height: 12px;
  font-size: 9px;
  border-radius: 50%;
  border: 1px solid rgb(255, 255, 255, 50%);
  display: inline-block;
  line-height: 12px;
  text-align: center;
  margin-right: 3px;
  vertical-align: top;
}
</style>