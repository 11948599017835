<template>
    <div>
        <div class="first">
            <img src="../../assets/images/SolutionSVG/smartYQ/first.png" alt="">
        </div>
        <div class="second">
            <img src="../../assets/images/SolutionSVG/smartYQ/second.png" alt="">
        </div>
        <div class="third">
            <img src="../../assets/images/SolutionSVG/smartYQ/third.png" alt="">
        </div>
        <div class="four">
            <img src="../../assets/images/SolutionSVG/smartYQ/four.png" alt="">
        </div>
        <div class="five">
            <img src="../../assets/images/SolutionSVG/smartYQ/five.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartYQ/six.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartYQ/seven.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartYQ/eight.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartYQ/nine.png" alt="">
        </div>
     
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
img{
    display: block;
    height: 100%;
    width: 100%;
}

div{
    font-size:0
}

</style>