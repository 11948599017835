<template>
    <div>
        <div class="first">
            <img src="../../assets/images/SolutionSVG/smartZZ/first.svg" alt="">
        </div>
        <div class="second">
            <img src="../../assets/images/SolutionSVG/smartZZ/second.svg" alt="">
        </div>
        <div class="third">
            <img src="../../assets/images/SolutionSVG/smartZZ/third.svg" alt="">
        </div>
        <div class="four">
            <img src="../../assets/images/SolutionSVG/smartZZ/four.svg" alt="">
        </div>
        <div class="five" style="background-color:#f5f5f5">
            <img src="../../assets/images/SolutionSVG/smartZZ/five.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartZZ/six.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
image{
    display: block;
    height: 100%;
}
div{
    font-size:0
}
.first{
    
    }
    .first img{
        width: 100%;
    }
    .second img{
        width: 100%;
    }
    .third img{
        width: 100%;
    }
    .four img{
        width: 100%;
    }
    .five img{
        width: 100%;
    }
    .six img{
        width: 100%;
    }
</style>