<template>
    <div>
        <div class="first">
            <img src="../../assets/images/SolutionSVG/smartZF/first.png" alt="">
        </div>
        <div class="second">
            <img src="../../assets/images/SolutionSVG/smartZF/second.png" alt="">
        </div>
        <div class="third">
            <img src="../../assets/images/SolutionSVG/smartZF/third.png" alt="">
        </div>
        <div class="four">
            <img src="../../assets/images/SolutionSVG/smartZF/four.png" alt="">
        </div>
        <div class="five">
            <img src="../../assets/images/SolutionSVG/smartZF/five.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartZF/six.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartZF/seven.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartZF/eight.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartZF/nine.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartZF/ten.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
img{
    display: block;
    height: 100%;
    width: 100%;
}

div{
    font-size:0
}

</style>