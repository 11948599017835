<template>
  <div class="top" id="topbanner" :style="style">
    <div class="logo" @click="home"></div>
    <div class="ability">
      <div class="items">
        <div class="menu"  @click="home" id="home">
          <span class>首页</span>
        
        
        </div>
      </div>
      <div class="items">
        <div class="menu" id="hycj">
          <span class>行业场景</span>
          <img src="../assets/menuAfter.png" alt />
          <div class="dropbox">
            <div
              class="drop"
              @click="IndustryScenario(item.id)"
              v-for="(item,k) of IndustryScenarioChild"
              :key="k"
              :style="style"
            >{{item.title }}</div>
          </div>
        </div>
      </div>
      <div class="items">
        <div class="menu menuHasDrop" id="jjfa" @click="jumpToSoluitionDaday">
          <span>解决方案</span>
          <img src="../assets/menuAfter.png" alt />
          <div class="dropbox" >
            <div
              class="drop"
              v-for="(item,k) of SoluitionChild "
              :key="k"
              @click.stop="jumpToSoluitionChild(item.id)"
              :style="style"
            >{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="items">
        <div class="menu" @click="Selectedcases" id="jxal">
          <span>精选案例</span>
          <div class="dropbox">
            <div
              class="drop"
              v-for="(item,k) of jxal "
              :key="k"
              @click="toCases(item.id)"
              :style="style"
            >{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="items">
        <div class="menu" @click="Servicecapability" id="fwnl">
          <span>服务能力</span>
          <div class="dropbox">
            <div class="drop1"></div>
          </div>
        </div>
      </div>
      <div class="items">
        <div class="menu" @click="aboutSidex" id="gysd">
          <span>关于思德</span>
          <div class="dropbox">
            <div class="drop1"></div>
          </div>
        </div>
      </div>
      <div class="items">
        <div class="menu" @click="joinour" id="jrwm">
          <span>加入我们</span>
          <div class="dropbox">
            <div class="drop1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
 
      value: "",
      style: { backgroundColor: "" },
      scrollTop: 0,
      IndustryScenarioChild: [
    
      ],
      SoluitionChild: [
 
      ],
      jxal:[
        // { name: "中国移动", eName: "yd" },
        // { name: "中国电信", eName: "dx" },
        // { name: "中国联通", eName: "lt" },
        // { name: "十大智慧行业", eName: "zhhy" },
        // { name: "其他", eName: "qt" },
      ],
      SolutionEname: ""
    };
  },
  mounted() {
  
    window.addEventListener("scroll", this.handleScroll);
    console.log(this.$route.path)



    this.$axios.get('getIndustry').then(res=>{
      console.log('我能听得到?topBanner的getIndustry',res)
      this.IndustryScenarioChild=res.data.data
    })


    this.$axios.get('getSolutionByHomePage').then(res=>{
      console.log('我能听得到?getSolutionByHomePage',res)
      this.SoluitionChild=res.data.data
    })


  },
  beforeDestroy () {
    
    window.removeEventListener("scroll", this.handleScroll);
;
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop <= 100 &&scrollTop > 0) {
        console.log('tmd',scrollTop /(scrollTop + 20))
        this.style.backgroundColor = `rgba(100, 97, 166,${scrollTop /
          (scrollTop + 5)}) `;
      } else if (scrollTop == 0) {
        this.style.backgroundColor = "rgb(100, 97, 166,1)";
      }
    },
    jumpToSoluitionDaday(){
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      let home =document.getElementById('home')
      home.style.fontFamily='SC_Light'
      hycj.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Bold'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      if (this.$route.path !== "/SoluitionDaday") {
        this.$router.push({name:"SoluitionDaday"});
      }
    
    },
    //跳转到路由(Soluition)
    jumpToSoluitionChild(id) {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      let home =document.getElementById('home')
      home.style.fontFamily='SC_Light'
      hycj.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Bold'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      if (this.$route.path !== "/Solution") {
        this.$router.push({name:"Solution",params:{id}});
      }
      
   
    },
    home() {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      let home =document.getElementById('home')
      home.style.fontFamily='SC_Bold'
      hycj.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Light'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      this.$router.push("/home");
      document.documentElement.scrollTop = 0;
      location.reload();

      
    },
    IndustryScenario(id) {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      let home =document.getElementById('home')
      home.style.fontFamily='SC_Light'
      hycj.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Light'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      hycj.style.fontFamily='SC_Bold'


      this.$router.push(
      {
        name:'IndustryScenario',
        params:{id}
      }

      );
  

   
      
    },
    Solution() {
      if (this.$route.path !== "/Solution") {
        this.$router.push("/Solution");
      }
   
    },
    Servicecapability() {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      let home =document.getElementById('home')
      home.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Light'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Bold'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      hycj.style.fontFamily='SC_Light'
      if (this.$route.path !== "/Servicecapability") {
        this.$router.push("/Servicecapability");
      }
   
    },
    aboutSidex() {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      let home =document.getElementById('home')
      home.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Light'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Bold'
      jrwm.style.fontFamily='SC_Light'
      hycj.style.fontFamily='SC_Light'
      if (this.$route.path !== "/aboutSidex") {
        this.$router.push("/aboutSidex");
      }
   
    },
    Selectedcases() {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      let home =document.getElementById('home')
      home.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Light'
      jxal.style.fontFamily='SC_Bold'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      hycj.style.fontFamily='SC_Light'
      if (this.$route.path !== "/Selectedcases") {
        this.$router.push("/Selectedcases");
      }
     
    
    },
    joinour() {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      let home =document.getElementById('home')
      home.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Light'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Bold'
      hycj.style.fontFamily='SC_Light'
      if (this.$route.path !== "/JoinUS") {
        this.$router.push("/JoinUS");
      }
     
    },

    toCases(id){
      location.reload();
      this.$router.push(
        {
          name:'Selectedcases',
          params:{id}
        },
        
    )
   
    }
  }
};
</script>

<style scoped>

.hehe {
  height: 200px;
}
.top {
  width: 100%;
  background-color: rgb(100, 97, 166,1);
  height: 64px;
  position: fixed;
  z-index: 999;
  transition: all 0.4s;
  /* background-image: radial-gradient(transparent 1px,#fff 1px); */

  background-size:4px 4px ;
  backdrop-filter: saturate(50%) blur(4px);
}

.logo {
  width: 140.3px;
  height: 47px;
  background-image: url(../assets/logo3.png);
  /* background-size: 100% 100%; */
  margin-left: 70px;
  margin-top: 7px;
  float: left;
}

.ability {
  font-size: 14px;
  float: right;
  /* margin-right: 9px; */
  line-height: 64px;
  display: flex;
}

.items {
  color: #fff;
  margin-right: 61px;
  margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
  width: 100%;
  height: 50px;
  line-height: 45px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  /* background-color: #fff; */
  font-family: "SC_Light";
 
}

.menu:hover {
  z-index: 999;
  cursor: pointer;
  overflow: visible;
  font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
  border-top: 0px solid #fff;
  margin-top:0px;
}

.drop {
  background-color: rgb(100, 97, 166,1);
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 13px;
  border-bottom:1px solid rgb(238, 238, 238,0.3);
  font-family: "SC_Light";
  transition: all 0.4s;
}

.drop1 {
  background: transparent;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
}

.drop:hover {
  font-family: "SC_Bold";
  cursor: pointer;
}
.menuHasDrop {
  margin: 0;
}

.menu img {
  width: 10px;
  margin-left: 6px;
  /* margin-bottom: 2px; */
}
</style>
