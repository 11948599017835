<template>
  <div>
    <topBanner></topBanner>
    <toTop @show='ishow'></toTop>
    <Popup></Popup>
   
    <router-view />
 
    <Bottom></Bottom>
  </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue";
import toTop from "./components/toTop.vue";
import Bottom from "@/components/bottom.vue";
import Popup from "@/components/Popup/Popup.vue";
export default {
  components: {
    toTop,
    topBanner,
    Bottom,
    Popup
  },
  mounted () {
    console.log(this.$route.path);
  },
  methods: {
    ishow(item) {
      console.log(item)
    }
  },
  data() {
    return {
      ishow23:false
    };
  }
};
</script>

<style scoped>
*{
  user-select:none
}
video {
    object-fit: cover;
}
</style>