<template>
    <div>
        <div class="first">
            <img src="../../assets/images/SolutionSVG/smartYL/first.png" alt="">
        </div>
        <div class="second">
            <img src="../../assets/images/SolutionSVG/smartYL/second.png" alt="">
        </div>
        <div class="third">
            <img src="../../assets/images/SolutionSVG/smartYL/third.png" alt="">
        </div>
        <div class="four">
            <img src="../../assets/images/SolutionSVG/smartYL/four.png" alt="">
        </div>
        <div class="five">
            <img src="../../assets/images/SolutionSVG/smartYL/five.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartYL/six.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartYL/seven.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartYL/eight.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartYL/nine.png" alt="">
        </div>
      
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
img{
    display: block;
    height: 100%;
    width: 100%;
}

div{
    font-size:0
}

</style>