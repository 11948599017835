import { render, staticRenderFns } from "./topBanner.vue?vue&type=template&id=aa1b7132&scoped=true&"
import script from "./topBanner.vue?vue&type=script&lang=js&"
export * from "./topBanner.vue?vue&type=script&lang=js&"
import style0 from "./topBanner.vue?vue&type=style&index=0&id=aa1b7132&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa1b7132",
  null
  
)

export default component.exports