import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../components/home.vue'
import Servicecapability from '../components/Servicecapability.vue'
import aboutSidex from '../components/aboutSidex.vue'
import Selectedcases from '../components/Selectedcases.vue'
import Solution from '../components/Solution.vue'
import IndustryScenario from '../components/IndustryScenario.vue';
import gocase from "../components/case.vue"
import JoinUS from '../components/personnel/joinUs.vue'
import category from '../components/personnel/category.vue'
import Campus from '../components/personnel/Campus.vue'
import categoryDetails from '../components/personnel/categoryDetails.vue'
import SoluitionDaday from '../components/SoluitionDaday.vue'
// import { CHROMIUM_VERSION } from 'video.js/dist/types/utils/browser'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
const routes = [
  { path: '/', redirect: '/home' },
  {

    path: '/home', component: home


  },
  { path: '/Servicecapability', component: Servicecapability },
  { path: '/aboutSidex', component: aboutSidex },
  { path: '/Selectedcases', component: Selectedcases },
  { path: '/Solution/:id', component: Solution,name:"Solution" },
  { path: '/IndustryScenario/:id', component: IndustryScenario,name:'IndustryScenario'},
  { path: '/case/:id', component: gocase,name:'case' },
  { path: '/JoinUS', component: JoinUS },
  { path: '/category', component: category },
  { path: '/Campus', component: Campus },
  { path: '/categoryDetails/:id', component: categoryDetails,name:'categoryDetails' },
  { path: '/SoluitionDaday', component: SoluitionDaday, name: 'SoluitionDaday' }

]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})





export default router
