<template>
    <div>
        <div class="first">
            <img src="../../assets/images/SolutionSVG/smartWenLv/first.png" alt="">
        </div>
        <div class="second">
            <img src="../../assets/images/SolutionSVG/smartWenLv/second.png" alt="">
        </div>
        <div class="third">
            <img src="../../assets/images/SolutionSVG/smartWenLv/third.png" alt="">
        </div>
        <div class="four">
            <img src="../../assets/images/SolutionSVG/smartWenLv/four.png" alt="">
        </div>
        <div class="five">
            <img src="../../assets/images/SolutionSVG/smartWenLv/five.png" alt="">
        </div>
        <div class="six">
            <img src="../../assets/images/SolutionSVG/smartWenLv/six.png" alt="">
        </div>
   
  
      
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
img{
    display: block;
    height: 100%;
    width: 100%;
}

div{
    font-size:0
}

</style>