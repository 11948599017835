import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    solution:'解决方案',
 
  },
  mutations: {
    changeSolution(state,item){
      state.solution=item
    },
  
  },
  actions: {
  },
  modules: {
  }
})
