<template>
    <div class="categroy">
        <div class="hehe">

        </div>
        <div class="categroyJob">
            <div @click="toList" class="goback"><i class="el-icon-caret-left"></i>返回</div>
            <div class="box_left">
                <div class="fbtime">
                    <div class="shijian">发布时间： {{data.createTime}}</div>
                   
                </div>
                <div class="fbtimeWZ">
                    <div class="position">{{ data.name}}</div>
                    <div class="applyJob" @click="applyJob">申请职位</div>
                </div>
                <div class="requirement">{{data.city}}<i>|</i>{{data.degree}}<i>|</i>{{data.name}}<i>|</i>职位ID：{{ data.jobId }}</div>
                <!-- <div class="profile">
                        为支持公司战略转型发展，有效牵引各部门工作，优化公司人力资源力量，需要引进大批德才兼备，教育背景完整、专业知识结构清晰的职场新人进行系统性优势打造，以有效保证公司基层储备及中坚力量支持。
                    </div> -->
                <div class="line"></div>
                <div class="position_work">工作职责</div>
                <div class="workList">
                    <div>{{data.duty}}</div>
               
                </div>
                <div class="position_work">任职要求</div>
                <div class="workList">
                    <div>{{data.request}}</div>
                
                </div>

            </div>
            <div class="box_right">
                <div class="newJob">最新职位</div>
                <div  @click="ToDetails(item.id)" class="jobList" v-for="(item,k) of jobList" :key="k"><span>急</span>{{item.name}}</div>
            
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'categoryDetail',
    data() {
        return {
            //上个页面传过来的id
            id:0,
            data: {},
            jobList:[],
            text:"1.负责客户的开拓与销售工作，执行并完成公司年度销售目标\n负责客户的定期拜访.沟通，进行客户关系的维护和进一步开发，与客户建立长期而稳定的合作关系，建立客户档案； \n3.能够基本沟通并理解客户需求，并传递公司的整体能力和解决方案；\n4.负责及时.准确地完成公司要求的各类日常销售报表；\n5.完成领导交办的其他工作。\n"

        }
    },
    watch: {
          $route(){
            if(this.$route.params.id!==undefined){
                let id = this.$route.params.id
                this.id=id
                this.$axios(`getRecruitDetail?id=${this.id}`).then(res=>{
            // console.log(res)
            this.data=res.data.data[0]

           let aaa= this.data.createTime.replace('T',' ')
            this.data.createTime=aaa
            console.log('123123123',this.data)
        })
            }

     
          }
        },

    mounted(){
      
      console.log(this.$route.params)
      let id = this.$route.params.id
                this.id=id
                this.$axios(`getRecruitDetail?id=${this.id}`).then(res=>{
            // console.log(res)
            this.data=res.data.data[0]

           let aaa= this.data.createTime.replace('T',' ')
            this.data.createTime=aaa
            console.log('123123123',this.data)
        })
   


this.$axios('getNewRecruit?count=5').then(res=>{
    this.jobList=res.data.data
    console.log(this.jobList)
})


    },
    methods: {
        applyJob(){
        window.open(this.data.bossLink,'_blank');

    },
        ToDetails(id) {
      console.log("我送过去了:", id);
      this.$router.push({
        name: "categoryDetails",
        params: {
          id
        }
      });
    },
        toList(){
            this.$router.go(-1)
        }
    }

}
</script>
<style scoped>
.hehe{
    height: 50px;
}
.top {
    width: 100%;
    background-color: #6461a6;
    height: 64px;
    position: fixed;
    z-index: 999;
}

.logo {
    width: 75px;
    height: 37px;
    background-image: url(../../assets/logo.png);
    background-size: 100% 100%;
    margin-left: 70px;
    margin-top: 13.5px;
    float: left;
}

.ability {
    font-size: 14px;
    float: right;
    margin-right: 9px;
    line-height: 64px;
    display: flex;
}


.items {
    color: #fff;
    margin-right: 61px;
    margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    position: relative;
    overflow: hidden;
    font-size: 14px;
    font-family: "SC_Light"
}


.menu:hover {
    z-index: 999;
    cursor: pointer;
    overflow: visible;
    font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
    border-top: 2px solid #fff;
}

.drop {
    background: #6461a6;
    text-align: center;
    width: 96px;
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    font-family: "SC_Light";
}

.drop1 {
    background: transparent;
    text-align: center;
    width: 96px;
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    font-size: 14px;

}

.drop:hover {
    font-family: "SC_Bold";
    cursor: pointer;
}
</style>
<style scoped>
/* 22 16 14 */
.categroy {
    width: 100%;
    padding-bottom: 80px;
    overflow: hidden;
}

.categroyJob {
    width: 1280px;
    overflow: hidden;
    margin: 0 auto;
    padding: 45px 0 120px;
}

.box_left {
    float: left;
    width: 870px;
    padding: 0 30px 0 50px;
    /* background: #f2f2f2; */
}

.fbtime {
    overflow: hidden;
    margin-top: 80px;
    margin-bottom: 34px;
    font-size: 16px;
    color: #333333;
    font-family: 'SC_Regular';
}

.fbtime .shijian {
    float: left;

}

.fbtime .share {
    float: right;
}

.share img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
}

.box_left div {
    flex: 1;
}

.fbtimeWZ {
    overflow: hidden;
    margin-top: 80px;
    margin-bottom: 10px;
}

.requirement {
    font-size: 14px;
    color: #333333;
    opacity: 0.6;
    line-height: 20px;
    margin-bottom: 50px;
}

.requirement i {
    font-style: normal;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
}

.position {
    font-size: 22px;
    color: #333333;
    font-family: 'SC_Bold';
    line-height: 40px;
    text-align: left;
    float: left;
    width: 270px;
}

.profile {
    color: rgba(51, 51, 51, 0.5);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    float: right;
}

.line {
    width: 100%;
    height: 1px;
    background: rgba(51, 51, 51, 0.5);
    /* margin-top:20px; */
}

.position_work {
    width: 100%;
    font-size: 20px;
    line-height: 35px;
    margin-top: 40px;
    color: #64619E;
    font-family: 'SC_Regular';
    text-align: left;
}

.applyJob {
    width: 116px;
    height: 38px;
    line-height: 38px;
    font-size: 16px;
    text-align: center;
    border-radius: 6px;
    color: #fff;
    background: #6461a6;
    /* margin: 100px auto 50px; */
    float: right;
}

.workList {
    width: 100%;
    line-height: 30px;
    font-size: 14px;
    color: #333333;
    font-family: 'SC_Light';
   
}

.workList div {
    width: 100%;
    text-align: left;
    white-space: pre-line
}

.box_right {
    float: right;
    width: 300px;
    margin-left: 30px;
    padding-bottom: 50px;
    /* background: #f2f2f2; */
}

.box_right div {
    margin-left: 30px;
    text-align: left;
}

.newJob {
    font-size: 30px;
    font-family: 'SC_Bold';
    color: #333333;
    margin-top: 34px;
    margin-bottom: 20px;
}

.jobList {
    color: #333333;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 15px;
}

.jobList span {
    width: 21px;
    height: 21px;
    line-height: 21px;
    font-size: 14px;
    color: #ffffff;
    background: #f00;
    display: inline-block;
    margin-right: 16px;
    text-align: center;
}

.goback i {
    display: inline-block;
    margin-right: 12px;
 
}
.goback{
    font-size: 14px;
}
</style>